<template>
  <v-row v-if="catalogs.length > 0">
    <v-col cols="12">
      <v-row>
        <v-col cols="12">
          <p class="mediumBlueMine OpenSansLight punt55Mine capLetterMine">
            {{ $t('landing.catalogs') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <!--<v-row>
        <v-spacer></v-spacer>
        <v-col
          cols="12"
          md="4"
          sm="8"
        >
          <v-form class="faq-search-input mx-auto">
            <v-text-field
              outlined
              :placeholder="$t('btn.search')"
              hide-details
              class="faq-search-input"
            >
              <template #prepend-inner>
                <v-icon
                  size="23"
                  class="mx-1"
                >
                  {{ icons.mdiMagnify }}
                </v-icon>
              </template>
            </v-text-field>
          </v-form>
        </v-col>
      </v-row>-->
          <v-row>
            <v-col
              v-for="(catalog, indC) in catalogs"
              :key="indC"
              cols="12"
              md="4"
              sm="12"
            >
              <CatalogsCardOne
                :catalog="catalog"
                @getCatalogs="getCatalogs"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-divider></v-divider>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiPlus, mdiChevronDown, mdiClockOutline,
  mdiMapMarkerOutline,
} from '@mdi/js'
import CatalogsCardOne from './CatalogsCardOne.vue'

export default {
  components: {
    CatalogsCardOne,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    config: {
      type: Object,
    },
  },
  setup() {
    return {
      urlRegister: `${process.env.VUE_APP_URL}register`,
      urlS3: process.env.VUE_APP_URL_S3,

      // icons
      icons: {
        mdiPlus,
        mdiChevronDown,
        mdiClockOutline,
        mdiMapMarkerOutline,
      },
      developerMeetup: [
        { icon: mdiClockOutline, title: 'Tuesday, 24 january, 10:20 - 12:30', subtitle: 'After 1 week' },
        { icon: mdiMapMarkerOutline, title: 'The Rochard NYC', subtitle: '1305 Lexington Ave, New York' },
      ],
    }
  },
  data() {
    return {
      urlS3: process.env.VUE_APP_API_URL_S3_HOTEL,
      catalogs: [],
    }
  },
  created() {
    this.getCatalogs()
  },
  methods: {
    getCatalogs() {
      this.axios
        .post('catalogs/list-catalogos', { active: true })
        .then(res => {
          this.catalogs = res.data.data
        })
    },
    redirectPage() {
      // window.open(this.urlRegister, '_blank')

      const route = this.$router.resolve({
        name: 'auth-register',
      })
      window.open(route.href, '_blank')
    },
  },
}
</script>

<style scoped>
@font-face {
  font-family: OpenSansLight;
  src: url(../../../assets/font/OpenSans-Light.ttf);
}

@font-face {
  font-family: OpenSansRegular;
  src: url(../../../assets/font/OpenSans-Regular.ttf);
}

@font-face {
  font-family: OpenSansSemibold;
  src: url(../../../assets/font/OpenSans-Semibold.ttf);
}

.OpenSansLight {
  font-family: OpenSansLight;
}

.OpenSansRegular {
  font-family: OpenSansRegular;
}

.OpenSansSemibold {
  font-family: OpenSansSemibold;
}

.mediumBlueMine {
  color: #1a5666;
}

.lightmBlueMine {
  color: #0198c7;
}

.standarMine {
  color: black;
}

.punt55Mine {
  font-size: 40px;
}

.punt55MineMov {
  font-size: 28px;
}

.punt32Mine {
  font-size: 20px;
}

.punt32MineMov {
  font-size: 20px;
}

.capLetterMine {
  text-transform: uppercase;
}
</style>
